import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import { getToken } from "../service/auth";
import "../css/SharedLayout.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ENDPOINT = "https://trio-teach-backend-erp.vercel.app/api/tax-rates";

function TaxPage() {
  const [editMode, setEditMode] = useState(false);
  const [currentTaxRateId, setCurrentTaxRateId] = useState(null);

  const [activeDropdown, setActiveDropdown] = useState(null);
  const [taxRates, setTaxRates] = useState([]);
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [totalTaxRate, setTotalTaxRate] = useState("");

  useEffect(() => {
    fetchTaxRates();
  }, []);

  const fetchTaxRates = async () => {
    try {
      const response = await axios.get(ENDPOINT, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setTaxRates(response.data);
    } catch (error) {
      console.error("Error fetching tax rates:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let response;
      if (editMode) {
        // Send a PATCH request to update the existing tax rate
        response = await axios.patch(
          `${ENDPOINT}/${currentTaxRateId}`,
          {
            country,
            province,
            totalTaxRate,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        // Update the state with the new tax rate list
        setTaxRates(
          taxRates.map((tr) =>
            tr._id === currentTaxRateId ? response.data : tr
          )
        );
        toast.success("Tax Rate Updated Successfully!");
      } else {
        // Send a POST request to add a new tax rate
        response = await axios.post(
          ENDPOINT,
          {
            country,
            province,
            totalTaxRate,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
          
        );
        toast.success("Tax Rate Added Successfully!");
        // Add the new tax rate to the state
        setTaxRates([...taxRates, response.data]);
      }
      // Reset the form to initial state and exit edit mode
      setCountry("");
      setProvince("");
      setTotalTaxRate("");
      setEditMode(false);
      setCurrentTaxRateId(null);
    } catch (error) {
      console.error("Error adding/updating tax rate:", error);
    }
  };

  const toggleDropdown = (taxRateId) => {
    setActiveDropdown(activeDropdown === taxRateId ? null : taxRateId);
  };

  const handleDelete = async (taxRateId) => {
    // Logic to delete tax rate
    try {
      const response = await axios.delete(`${ENDPOINT}/${taxRateId}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      // Filter out the deleted tax rate from the taxRates array
      setTaxRates(taxRates.filter((taxRate) => taxRate._id !== taxRateId));
      toast.success("Tax Rate Deleted Successfully!");
    } catch (error) {
      console.error("Error deleting tax rate:", error);
    }
  };
  const handleUpdate = (taxRate) => {
    // Prefill the form with the current tax rate's information
    setCountry(taxRate.country);
    setProvince(taxRate.province);
    setTotalTaxRate(taxRate.totalTaxRate);

    // Set editing mode and store the current tax rate id
    setEditMode(true);
    setCurrentTaxRateId(taxRate._id); // Make sure taxRate has a property _id

    // Close the dropdown if it's open
    setActiveDropdown(null);
  };

  return (
    <div className="layout">
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="companyPage_container">
        <div className="main-content">
          <h1>Tax Rates</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Province"
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Total Tax Rate"
              value={totalTaxRate}
              onChange={(e) => setTotalTaxRate(e.target.value)}
              required
            />
            <button type="submit">
              {editMode ? "Update Tax Rate" : "Add Tax Rate"}
            </button>{" "}
          </form>
          <table>
            <thead>
              <tr>
                <th>Country</th>
                <th>Province</th>
                <th>Total Tax Rate %</th>
              </tr>
            </thead>
            <tbody>
              {taxRates.map((taxRate) => (
                <tr key={taxRate._id}>
                  <td>{taxRate.country}</td>
                  <td>{taxRate.province}</td>
                  <td>{taxRate.totalTaxRate}</td>
                  <td>
                    <div className="relative-container">
                      <button onClick={() => toggleDropdown(taxRate._id)}>
                        ...
                      </button>
                      {activeDropdown === taxRate._id && (
                        <div className="dropdown-content show">
                          <button onClick={() => handleUpdate(taxRate)}>
                            Update
                          </button>
                          <button onClick={() => handleDelete(taxRate._id)}>
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TaxPage;
