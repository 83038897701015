import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import "../css/SharedLayout.css";
import "../css/ExpensesCategoryPage.css"; // Adjust the path according to your file structure
import { getToken } from "../service/auth"; // Ensure this path matches your project structure
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ENDPOINT = "https://trio-teach-backend-erp.vercel.app";

function ExpensesCategoryPage() {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [note, setNote] = useState("");

  // Define fetchExpenseCategories outside of useEffect to make it accessible throughout the component
  const fetchExpenseCategories = async () => {
    try {
      const response = await axios.get(`${ENDPOINT}/api/expense-categories`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching expense categories:", error);
    }
  };

  useEffect(() => {
    fetchExpenseCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name, description, note };

    try {
      if (isEditing && editingCategoryId) {
        // Update existing category
        await axios.patch(
          `${ENDPOINT}/api/expense-categories/${editingCategoryId}`,
          data,
          {
            headers: { Authorization: `Bearer ${getToken()}` },
          }
        );
        toast.success("Category updated successfully");
      } else {
        // Add new category
        await axios.post(`${ENDPOINT}/api/expense-categories`, data, {
          headers: { Authorization: `Bearer ${getToken()}` },
        });
        toast.success("Category added successfully"); // For demonstration, consider using a more user-friendly notification system
      }
      // Common code to run after either add or update
      fetchExpenseCategories(); // Refresh the list of categories
      resetFormAndState(); // Reset form fields and component state
    } catch (error) {
      console.error("Error submitting the expense category:", error);
      alert("Error submitting the expense category"); // For demonstration, consider using a more user-friendly notification system
    }
  };

  // Function to reset form fields and component state
  const resetFormAndState = () => {
    setName("");
    setDescription("");
    setNote("");
    setIsEditing(false);
    setEditingCategoryId(null);
    setActiveDropdown(null); // Close any open dropdown
  };

  const handleEditClick = (category) => {
    setIsEditing(true);
    setEditingCategoryId(category._id);
    setName(category.name);
    setDescription(category.description);
    setNote(category.note);
  };

  const toggleDropdown = (categoryId) => {
    setActiveDropdown(activeDropdown === categoryId ? null : categoryId);
  };
  const handleDelete = async (categoryId) => {
    try {
      // Send a DELETE request to the server
      await axios.delete(`${ENDPOINT}/api/expense-categories/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      toast.success("Category deleted successfully!");

      // Update the categories state to filter out the deleted category
      const updatedCategories = categories.filter(
        (category) => category._id !== categoryId
      );
      setCategories(updatedCategories);

      // Optionally, show a success message or toast notification to the user
      console.log("Category deleted successfully");

      // Close any open dropdowns
      setActiveDropdown(null);
    } catch (error) {
      // Handle errors, such as showing an error message or toast notification
      console.error("Error deleting category:", error);
      // Optionally, inform the user that the delete operation failed
    }
  };

  const handleEdit = (category) => {
    // Prepare the component to update an existing category
    setIsEditing(true);
    setEditingCategoryId(category._id);
    setName(category.name);
    setDescription(category.description);
    setNote(category.note);
    // Optionally, close the dropdown
    setActiveDropdown(null);
  };

  return (
    <div className="layout">
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="companyPage_container">
        <div className="main-content">
          <h1>Expense Categories Page</h1>
          <p>Manage your expense categories here.</p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <textarea
              placeholder="Note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            ></textarea>
            <button type="submit">
              {isEditing ? "Update Category" : "Add Category"}
            </button>
          </form>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Note</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr key={category._id}>
                  <td>{category.name}</td>
                  <td>{category.description || "No Description"}</td>
                  <td>{category.note || "No Note"}</td>
                  <td>
                    <div className="relative-container">
                      <button onClick={() => toggleDropdown(category._id)}>
                        ...
                      </button>
                      {activeDropdown === category._id && (
                        <div className="dropdown-content show">
                          <button onClick={() => handleEdit(category)}>
                            Update
                          </button>
                          <button onClick={() => handleDelete(category._id)}>
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ExpensesCategoryPage;
