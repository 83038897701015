import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../css/SignupPage.css"; // Ensure the path to your CSS file is correct
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_ENDPOINT = "https://trio-teach-backend-erp.vercel.app";

function SignupPage() {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${API_ENDPOINT}/auth/signup`, formData);
      toast.success("Signup successful!");
      navigate("/login"); // Use navigate for redirection
    } catch (error) {
      console.error(
        "Signup error:",
        error.response ? error.response.data : error
      );
    }
  };

  return (
    <div className="signup-page">
      <ToastContainer />
      <div className="signup-left">
        <p className="fullStack">
          Join the Fun Side! 🚀 Let's Create Amazing Things Together
        </p>

        <div className="image-container">
          <img
            src="https://i.ibb.co/MDGwrM0/Screenshot-2024-03-29-at-3-36-20-PM.png"
            alt="Profile 1"
            className="profile-image"
          />
        </div>
        <div className="info-sections">
          <div className="info-container">
            <h2>Who We Are</h2>
            <p>
              We are Onur Korkmaz and Safa Aru, dynamic Full Stack Developers
              with a shared vision for innovative web solutions and efficient
              system integrations. With a solid foundation in Computer Science
              and over three years of hands-on experience in the tech industry,
              we specialize in the design and implementation of bespoke ERP
              solutions that drive business success.
            </p>
          </div>
        </div>
        <div className="info-container">
          <h2>Our Experience</h2>
          <p>
            As the creators behind an array of impactful projects, from
            forward-thinking e-commerce platforms to comprehensive management
            systems, we bring a wealth of expertise to the table. Our
            proficiency with JavaScript technologies like Node.js, React, and
            Angular, combined with our in-depth knowledge of databases including
            MongoDB, Firebase, and GraphQL, allows us to deliver systems that
            not only meet but exceed expectations.
          </p>
        </div>
        <div className="info-container">
          <h2>Why Choose Our ERP System?</h2>
          <p>
            Our ERP system stands out due to its tailor-made approach to
            business processes. It encapsulates our commitment to quality,
            innovation, and seamless user experience. -Streamline your
            operations with a system designed for speed and agility. -Enhances
            Efficiency -Integrates Seamlessly ,Scales With You ,Offers
            Reliability
          </p>
        </div>
      </div>
      <div className="signup-right">
        <div className="form-container">
          <form className="signup-form" onSubmit={handleSubmit}>
            <div className="form-control-signup">
              <label htmlFor="username" className="inputLabelsignup">
                Username:
              </label>
              <input
                id="username"
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
                className="inputClassSignup"
              />
            </div>
            <div className="form-control-signup">
              <label htmlFor="email" className="inputLabelsignup">
                Email:
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="inputClassSignup"
              />
            </div>
            <div className="form-control-signup">
              <label htmlFor="password" className="inputLabelsignup">
                Password:
              </label>
              <input
                id="password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="inputClassSignup"
              />
            </div>
            <div className="form-control-signup">
              <label htmlFor="firstName" className="inputLabelsignup">
                First Name:
              </label>
              <input
                id="firstName"
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                className="inputClassSignup"
              />
            </div>
            <div className="form-control-signup">
              <label htmlFor="lastName" className="inputLabelsignup">
                Last Name:
              </label>
              <input
                id="lastName"
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                className="inputClassSignup"
              />
            </div>
            <div className="form-control-signup">
              <label htmlFor="phoneNumber" className="inputLabelsignup">
                Phone Number:
              </label>
              <input
                id="phoneNumber"
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                className="inputClassSignup"
              />
            </div>
            <button type="submit" className="signup-button">
              Sign Up
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignupPage;
