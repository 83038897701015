import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import "../css/LoginPage.css"; // CSS dosya yolunuz doğru olduğundan emin olun

const API_ENDPOINT = "https://trio-teach-backend-erp.vercel.app";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${API_ENDPOINT}/auth/login`, {
        email,
        password,
      });

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.userId);
      localStorage.setItem("userRole", response.data.role);

      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error.response || error);
    }
  };
  const DatabaseIcon = () => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C7 2 4 3.5 4 3.5v17s3-1.5 8-1.5 8 1.5 8 1.5v-17S17 2 12 2z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 8.5s3-1.5 8-1.5 8 1.5 8 1.5M4 14.5s3-1.5 8-1.5 8 1.5 8 1.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const UserIcon = () => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 12a4 4 0 100-8 4 4 0 000 8zm0 2c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const SecurityIcon = () => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 10v4c0 1.1.9 2 2 2s2-.9 2-2v-4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const NodejsIcon = () => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 3l12 6.93v13.13L6 16.07V3z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 3v13.87L6 22M18 9l-6 3.46"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const ReactIcon = () => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.02 2.2c-.96-.56-2.08-.56-3.04 0C4.28 5.12 1.95 9.47 2 12c-.05 2.53 2.28 6.88 9.02 9.8.96.56 2.08.56 3.04 0 6.74-2.92 9.07-7.27 9.02-9.8.05-2.53-2.28-6.88-9.02-9.8z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 12c1.26-4.6 4.67-8 9.98-8 5.31 0 8.72 3.4 9.98 8-1.26 4.6-4.67 8-9.98 8-5.31 0-8.72-3.4-9.98-8z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <div className="login-page">
      <div className="login-left">
        <img
          src="https://files.oaiusercontent.com/file-DCi5n0GtBJXZ1rGLhnDgLzqI?se=2024-03-29T17%3A23%3A46Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3Df7fb76e6-6bd5-4f45-913a-7bfa5a346ac0.webp&sig=v9wEIP0k1eonoywqluJPGhN0ZcQOJhgS3fvs3Bfx/xM%3D"
          alt="Decorative"
        />
        <div className="login-info">
          <h2>Why Choose Our System?</h2>
          <p className="test2">
            Discover the power of seamless integration with our ERP system.
            Uniting essential business functions like finance, HR, supply chain,
            and customer relationship management, our platform ensures a
            streamlined workflow and enhanced efficiency. Embrace the synergy of
            interconnected modules, driving your business forward with advanced
            data analysis and real-time insights. Welcome to the future of
            business management.
          </p>
          <div className="test">
          <div className="icons-container">
          <DatabaseIcon className="icon database-icon" />
            <UserIcon className="icon" />
            <SecurityIcon className="icon" />
            <NodejsIcon className="icon" />
            <ReactIcon className="icon" />
          </div>
          
          </div>
        </div>
      </div>
      <div className="login-right">
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email" className="inputLabel">
              Email:
            </label>
            <input
              id="email"
              type="email"
              className="inputClass"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="inputLabel">
              Password:
            </label>
            <input
              id="password"
              type="password"
              className="inputClass"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
          <div className="login-links2">
            <Link to="/forgot-password">Forgot Password?</Link>
            <Link to="/signup">Signup</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
