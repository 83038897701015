import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import "../css/SharedLayout.css";
import "../css/ProductPage.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getToken } from "../service/auth"; // Adjust this import if necessary

const ENDPOINT = "https://trio-teach-backend-erp.vercel.app";

function ProductPage() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [name, setName] = useState("");
  const [currency, setCurrency] = useState("EUR");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null); // State to store the ID of the selected product
  const [isUpdateMode, setIsUpdateMode] = useState(false); // State to track if the form is in update mode
  const [openDropdownId, setOpenDropdownId] = useState(null); // State to track the open dropdown

  // Define fetchProducts outside of useEffect to make it accessible throughout the component
  const fetchProducts = async () => {
    const token = getToken();
    try {
      const productsResponse = await axios.get(`${ENDPOINT}/api/products`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProducts(productsResponse.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Fetch product categories
  const fetchProductCategories = async () => {
    const token = getToken();
    try {
      const categoriesResponse = await axios.get(
        `${ENDPOINT}/api/product-categories`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCategories(categoriesResponse.data);
    } catch (error) {
      console.error("Error fetching product categories:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchProductCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getToken();
    try {
      if (isUpdateMode) {
        await axios.patch(
          `${ENDPOINT}/api/products/${selectedProductId}`,
          {
            name,
            category: selectedCategory,
            currency,
            price,
            description,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        toast.success("Product updated successfully!");
        // Refresh the list of products
        fetchProducts();
        // Reset form fields and state variables
        resetForm();
      } else {
        await axios.post(
          `${ENDPOINT}/api/products`,
          {
            name,
            category: selectedCategory,
            currency,
            price,
            description,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        // Refresh the list of products
        toast.success("Product added successfully!");
        fetchProducts();
      }
    } catch (error) {
      console.error("Error adding/updating product:", error);
    }
  };

  const resetForm = () => {
    setName("");
    setSelectedCategory("");
    setCurrency("EUR");
    setPrice("");
    setDescription("");
    setSelectedProductId(null);
    setIsUpdateMode(false);
  };

  const handleUpdate = async (productId) => {
    const selectedProduct = products.find(
      (product) => product._id === productId
    );
    if (selectedProduct) {
      setName(selectedProduct.name);
      setSelectedCategory(
        selectedProduct.category ? selectedProduct.category._id : ""
      );
      setCurrency(selectedProduct.currency);
      setPrice(selectedProduct.price);
      setDescription(selectedProduct.description);
      setSelectedProductId(productId);
      setIsUpdateMode(true);
    }
  };

  const handleDelete = async (productId) => {
    const token = getToken();
    try {
      await axios.delete(`${ENDPOINT}/api/products/${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Refresh the list of products
      toast.success("Product deleted successfully!");
      fetchProducts();
      
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const toggleDropdown = (productId) => {
    setOpenDropdownId(openDropdownId === productId ? null : productId);
  };

  return (
    <div className="layout">
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="companyPage_container">
        <h1>Product Page</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            required
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
          <select
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option value="EUR">Euro</option>
            <option value="USD">Dollar</option>
            {/* Add more currencies as needed */}
          </select>
          <input
            type="number"
            placeholder="Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
          <textarea
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
          <button type="submit">
            {isUpdateMode ? "Update Product" : "Add Product"}
          </button>
        </form>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Category</th>
              <th>Currency</th>
              <th>Price</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product._id}>
                <td>{product.name}</td>
                <td>
                  {product.category ? product.category.name : "No Category"}
                </td>
                <td>{product.currency}</td>
                <td>{product.price}</td>
                <td>{product.description}</td>
                <td>
                  <div className="relative-container">
                    <button onClick={() => toggleDropdown(product._id)}>
                      ...
                    </button>
                    {openDropdownId === product._id && (
                      <div className="dropdown-content show">
                        <button onClick={() => handleUpdate(product._id)}>
                          Update
                        </button>
                        <button onClick={() => handleDelete(product._id)}>
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ProductPage;
