import React from "react";
import { Link } from "react-router-dom";
import "../css/HomePage.css"; // Make sure the path to your CSS file is correct
import Footer from "./Footer";
function HomePage() {
  const customerReviews = [
    { name: "Alice", quote: "Amazing service! Highly recommend.", rating: 5 },
    { name: "Bob", quote: "Excellent support and great value.", rating: 4 },
    {
      name: "Charlie",
      quote: "Truly innovative solutions for any business.",
      rating: 5,
    },
    // ... you can add more reviews here
  ];
  const pricingTiers = [
    {
      name: "Starter",
      price: "$25",
      features: [
        "Access for up to 3 users",
        "Standard data capacity",
        "Regular speed for routine tasks",
      ],
    },
    {
      name: "Premium",
      price: "$65",
      features: [
        "Access for up to 7 users",
        "24/7 support and help desk",
        "High-speed data processing",
        "Custom database options",
        "Weekly system updates",
      ],
    },
    {
      name: "Enterprise",
      price: "$199",
      features: [
        "Access for up to 10 users",
        "Optimized for business scaling",
        "Advanced data protection",
        "24/7 online support and in-person service in Canada",
        "Daily system updates for optimal performance",
      ],
    },
  ];

  // Define the features you want to include on the home page
  const features = [
    {
      title: "Be Productive",
      description:
        "Our ERP system serves as the command center for your enterprise, offering real-time insights across departments. From finance to HR, from inventory to customer relations – every facet of your business is unified under a single digital roof. This means improved coordination, reduced errors, and a single source of truth for decision-making.",
      image: "https://i.ibb.co/D4bDSQp/p1.png",
    },
    {
      title: "Be Productive",
      description:
        "Eliminate redundant tasks and automate key processes with our advanced ERP solutions. Watch as workflow automation propels your business forward, reducing manual workloads and freeing your team to focus on strategic initiatives. With our streamlined process flows, your business can achieve higher productivity and efficiency.",
      image: "https://i.ibb.co/PjgmGP8/p2.png",
    },
    {
      title: "Be Productive",
      description:
      "Grow without restraint as our ERP adapts to your expanding operations. Whether it’s new market ventures, increased customer demand, or a growing workforce, our system scales in sync with your growth. Enjoy the flexibility of an ERP that evolves with you, ensuring you're equipped for the challenges of tomorrow.",      image: "https://i.ibb.co/P6MrTy2/p3.png",
    },
  ];

  return (
    <div className="new-home-container">
      {/* Main content */}
      <div className="new-home-content">
        <h1>Revolutionize Your Workflow</h1>
        <p>
          Discover unparalleled efficiency with our next-gen ERP & CRM
          solutions. Elevate your business to new heights today.
        </p>
        <div className="new-home-buttons">
          <Link to="/signup" className="btn btn-primary">
            Begin Your Journey
          </Link>
          <Link to="/login" className="btn btn-secondary">
            Enter the Realm
          </Link>
        </div>
      </div>
      <div className="new-home-image">
        <img
          src="https://yourimageurl.com/new-image.jpg"
          alt="Innovative Solutions"
        />
      </div>
      {/* Feature section */}
      <div className="features-container">
        {features.map((feature, index) => (
          <div className="feature-item" key={index}>
            <div
              className="feature-image"
              style={{ backgroundImage: `url(${feature.image})` }}
            ></div>
            <h2 className="feature-title">{feature.title}</h2>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
      {/* Pricing section */}
      <div className="pricing-container">
        {pricingTiers.map((tier, index) => (
          <div className="pricing-item" key={index}>
            <h2>{tier.name}</h2>
            <p className="pricing-price">
              {tier.price}
              <span>/month</span>
            </p>
            <p>What you will get</p>
            <ul className="pricing-features">
              {tier.features.map((feature, featureIndex) => (
                <li key={featureIndex}>{feature}</li>
              ))}
            </ul>
            <Link to="/login" className="btn btn-primary">
              Pre Order Now
            </Link>
          </div>
        ))}
      </div>
      {/* Customer review section */}
      <div className="review-section">
        {customerReviews.map((review, index) => (
          <div className="review-item" key={index}>
            <blockquote className="review-quote">"{review.quote}"</blockquote>
            <p className="review-name">- {review.name}</p>
            <div className="review-rating">{"★".repeat(review.rating)}</div>
          </div>
        ))}
      </div>
      {/* Contact Us section */}
      <div className="contact-us-section">
        <h2>Contact Us</h2>
        <p>Send us a message and we'll get back to you shortly.</p>
        <form className="contact-form">
          <input type="text" placeholder="Your Name" required />
          <input type="email" placeholder="Your Email" required />
          <textarea placeholder="Your Message" required></textarea>
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
        </form>
      </div>

      <Footer />

      {/* Footer would go here */}
    </div>
  );
}

export default HomePage;
