import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "../css/SubscriptionPage.css";
import "../css/SharedLayout.css";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios here
import { getToken } from "../service/auth"; // Adjust this import if necessary
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function SubscriptionPage() {
  const navigate = useNavigate();

  // This should be replaced with the actual logic to retrieve the authenticated user's ID
  const currentUserId = localStorage.getItem("userId") || "placeholderUserId";

  useEffect(() => {
    if (currentUserId === "placeholderUserId") {
      console.error("Invalid user ID. Ensure the user is logged in.");
      navigate("/login");
    }
  }, [currentUserId, navigate]);
  const [selectedPlan, setSelectedPlan] = useState(null); // State to track the selected plan for upgrade

  const plans = [
    
    {
      name: "Starter",
      priceId: "price_1OvoXOBm4TSYA4w3CE1SiGIu", // Use the Stripe Price ID for the Starter plan
      description: "$24 / month - Include 3 Users",
      features: [
        "Access for up to 3 users.",
        "Standard data capacity.",
        "Regular speed for routine tasks.",
      ],
      buttonColor: "#00A8FF",
    },
    {
      name: "Premium",
      priceId: "price_1OvoclBm4TSYA4w37j1H05z8", // Use the Stripe Price ID for the Premium plan
      description: "$76 / month - Include 7 Users",
      features: [
        "Access for up to 7 users.",
        "24/7 support and help desk.",
        "High-speed data processing.",
        "Custom database options.",
        "Manage Expenses.",
        "Weekly system updates.",
      ],
      buttonColor: "#9C88FF",
    },
    {
      name: "Enterprise",
      priceId: "price_1Ovod0Bm4TSYA4w3qU9towGx", // Use the Stripe Price ID for the Enterprise plan
      description: "$132 / month - Include 10 Users",
      features: [
        "Access for up to 10 users.",
        "Optimized for business scaling.",
        "Advanced data protection.",
        "24/7 online support and in-person service in Canada.",
        "Daily system updates for optimal performance.",
      ],
      buttonColor: "#FBC531",
    },
  ];

  const handlePlanSelect = (plan) => {
    console.log("Navigating with:", plan.priceId, currentUserId); // Debugging statement
    navigate("/payment", {
      state: {
        planPriceId: plan.priceId,
        userId: currentUserId,
        subscriptionType: plan.name,
      },
    });
  };
  const cancelSubscription = async () => {
    const authToken = getToken();
    const userId = currentUserId; // Ensure this is correctly retrieved
    console.log(`Attempting to cancel subscription for user ID: ${userId}`); // Debugging log

    try {
      const response = await axios.post(
        "https://trio-teach-backend-erp.vercel.app/api/subscriptions/cancel",
        { userId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Cancellation successful:", response.data);
      toast.success("Subscription cancelled successfully."); // Display success notification
        // alert("Subscription cancelled successfully.");
    } catch (error) {
      console.error("Failed to cancel subscription:", error);
      alert("Failed to cancel the subscription. Please try again.");
    }
  };

  return (
    <div className="layout">
      <ToastContainer />
      <Sidebar />
      <div className="companyPage_container">


      <div className="main-content">
        <h1>Pricing</h1>
        <button onClick={cancelSubscription}>Cancel Subscription</button>

        <div className="billing-toggle">
          <button className="billing-button active">Monthly Billing</button>
        </div>
        <div className="plans-container">
          {plans.map((plan) => (
            <div
              className="plan"
              key={plan.id}
              onClick={() => handlePlanSelect(plan)}
              style={{ cursor: "pointer" }}
            >
              <h2>{plan.name}</h2>
              <p className="price">{plan.price}</p>
              <p>{plan.description}</p>
              <ul>
                {plan.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
              <button style={{ backgroundColor: plan.buttonColor }}>
                Select
              </button>
            </div>
          ))}
        </div>
      </div>
      </div>
    </div>
  );
}

export default SubscriptionPage;
