import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import "../css/SharedLayout.css";
import "../css/ProductPage.css";
import "../css/ProductCategoryPage.css";
import { getToken } from "../service/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ENDPOINT = "https://trio-teach-backend-erp.vercel.app";

function ProductCategoryPage() {
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [note, setNote] = useState("");
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingCategoryId, setEditingCategoryId] = useState("");

  useEffect(() => {
    fetchProductCategories();
  }, []);

  const fetchProductCategories = async () => {
    try {
      const response = await axios.get(`${ENDPOINT}/api/product-categories`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching product categories:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = getToken();
      if (isEditing) {
        await axios.patch(
          `${ENDPOINT}/api/product-categories/${editingCategoryId}`,
          { name, description, note },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        toast.success("Category updated successfully!");
      } else {
        await axios.post(
          `${ENDPOINT}/api/product-categories`,
          { name, description, note },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        toast.success("Category added successfully!");
      }
      resetForm();
      fetchProductCategories();
    } catch (error) {
      console.error("Error adding/updating product category:", error);
    }
  };

  const handleEdit = (event, category) => {
    event.stopPropagation();
    setIsEditing(true);
    setEditingCategoryId(category._id);
    setName(category.name);
    setDescription(category.description);
    setNote(category.note);
    setOpenDropdownId(null); // You might not need to close the dropdown immediately on edit
  };

  const handleDelete = async (categoryId) => {
    try {
      await axios.delete(`${ENDPOINT}/api/product-categories/${categoryId}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      toast.success("Category deleted successfully!");
      fetchProductCategories();
    } catch (error) {
      console.error("Error deleting product category:", error);
    }
  };

  const toggleDropdown = (categoryId) => {
    setOpenDropdownId(openDropdownId === categoryId ? null : categoryId);
  };

  const resetForm = () => {
    setIsEditing(false);
    setEditingCategoryId("");
    setName("");
    setDescription("");
    setNote("");
    setOpenDropdownId(null);
  };

  return (
    <div className="layout">
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="companyPage_container">
        <div className="main-content">
          <h1>Product Categories Page</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <textarea
              placeholder="Note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            ></textarea>
            <button type="submit">
              {isEditing ? "Update Category" : "Add Category"}
            </button>
          </form>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Note</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr key={category._id}>
                  <td>{category.name}</td>
                  <td>{category.description || "No Description"}</td>
                  <td>{category.note || "No Note"}</td>
                  <td>
                    <div className="relative-container">
                      <button onClick={() => toggleDropdown(category._id)}>
                        ...
                      </button>
                      {openDropdownId === category._id && (
                        <div
                          className="dropdown-content show"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEdit(e, category);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(category._id);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ProductCategoryPage;
