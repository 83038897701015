import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import { getToken } from "../service/auth";
import "../css/BusinessPage.css"; // Ensure the CSS path is correct for your project
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BASE_URL = "https://trio-teach-backend-erp.vercel.app/api/business";

function Business() {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [businesses, setBusinesses] = useState([]);
  const [newBusiness, setNewBusiness] = useState({
    businessName: "",
    businessAddress: "",
    businessPhone: "",
    businessEmail: "",
  });
  const [editingBusinessId, setEditingBusinessId] = useState(null);

  useEffect(() => {
    fetchBusinesses();
  }, []);

  const fetchBusinesses = async () => {
    try {
      const token = getToken();
      const response = await axios.get(BASE_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBusinesses(response.data);
    } catch (error) {
      console.error("Failed to fetch businesses", error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const token = getToken();
    try {
      if (editingBusinessId) {
        await axios.patch(`${BASE_URL}/${editingBusinessId}`, newBusiness, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Business updated successfully!");
      } else {
        await axios.post(BASE_URL, newBusiness, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Business added successfully!");
      }
      resetFormAndRefreshBusinesses();
    } catch (error) {
      console.error("Failed to submit business", error);
    }
  };

  const updateBusinessField = (field, value) => {
    setNewBusiness({ ...newBusiness, [field]: value });
  };

  const deleteBusiness = async (businessId) => {
    try {
      const token = getToken();
      await axios.delete(`${BASE_URL}/${businessId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchBusinesses();
      toast.success("Business deleted successfully!");
    } catch (error) {
      console.error("Failed to delete business", error);
    }
  };

  const populateFormForEdit = (business) => {
    setNewBusiness({
      businessName: business.businessName,
      businessAddress: business.businessAddress,
      businessPhone: business.businessPhone,
      businessEmail: business.businessEmail,
    });
    setEditingBusinessId(business._id);
  };

  const resetFormAndRefreshBusinesses = () => {
    setNewBusiness({
      businessName: "",
      businessAddress: "",
      businessPhone: "",
      businessEmail: "",
    });
    setEditingBusinessId(null);
    fetchBusinesses();
  };
  const toggleDropdown = (businessId) => {
    setActiveDropdown(activeDropdown === businessId ? null : businessId);
  };

  return (
    <div className="layout">
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="companyPage_container">
        <div className="main-content">
          <h1>Our Business Information</h1>
          <form onSubmit={handleFormSubmit}>
            <input
              type="text"
              placeholder="Business Name"
              value={newBusiness.businessName}
              onChange={(e) =>
                updateBusinessField("businessName", e.target.value)
              }
            />
            <input
              type="text"
              placeholder="Address"
              value={newBusiness.businessAddress}
              onChange={(e) =>
                updateBusinessField("businessAddress", e.target.value)
              }
            />
            <input
              type="text"
              placeholder="Phone"
              value={newBusiness.businessPhone}
              onChange={(e) =>
                updateBusinessField("businessPhone", e.target.value)
              }
            />
            <input
              type="email"
              placeholder="Email"
              value={newBusiness.businessEmail}
              onChange={(e) =>
                updateBusinessField("businessEmail", e.target.value)
              }
            />
            <button type="submit">
              {editingBusinessId ? "Update Business" : "Add Business"}
            </button>
            {editingBusinessId && (
              <button type="button" onClick={resetFormAndRefreshBusinesses}>
                Cancel Edit
              </button>
            )}
          </form>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {businesses.map((business) => (
                <tr key={business._id}>
                  <td>{business.businessName}</td>
                  <td>{business.businessAddress}</td>
                  <td>{business.businessPhone}</td>
                  <td>{business.businessEmail}</td>
                  <td>
                    <div className="relative-container">
                      <button onClick={() => toggleDropdown(business._id)}>
                        ...
                      </button>
                      {activeDropdown === business._id && (
                        <div className="dropdown-content show">
                          <button onClick={() => populateFormForEdit(business)}>
                            Edit
                          </button>
                          <button onClick={() => deleteBusiness(business._id)}>
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Business;
