import React, { useEffect, useState } from "react";
import axios from "axios";
import "../css/DashboardChart.css";

import { getToken } from "../service/auth";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement, // Import necessary for Pie chart
} from "chart.js";

// Register necessary components for both Bar and Pie charts
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const DashboardChart = () => {
  const [invoiceChartData, setInvoiceChartData] = useState({
    labels: [], // Will hold invoice numbers or dates
    datasets: [
      {
        label: "Invoice Totals",
        data: [], // Will hold the total value of each invoice
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "rgb(255, 99, 132)",
        borderWidth: 1,
      },
    ],
  });
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Product Prices",
        data: [],
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
    ],
  });

  const [taxRateData, setTaxRateData] = useState({
    labels: [], // Countries or provinces
    datasets: [
      {
        label: "Total Tax Rates",
        data: [], // Tax rates
        backgroundColor: "rgba(255, 205, 86, 0.5)",
        borderColor: "rgba(255, 205, 86, 1)",
        borderWidth: 1,
      },
    ],
  });

  const [expenseData, setExpenseData] = useState({
    labels: [], // This could be expense categories, dates, etc.
    datasets: [
      {
        label: "Total Expenses",
        data: [], // Total expense amounts
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  });

  const fetchTaxRates = async () => {
    const token = getToken();
    try {
      const response = await axios.get("https://trio-teach-backend-erp.vercel.app/api/tax-rates", {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      // Assuming each tax rate entry includes country, province, and totalTaxRate
      const taxRatesByCountry = response.data.map(
        (taxRate) => `${taxRate.country} - ${taxRate.province}`
      );
      const totalTaxRates = response.data.map(
        (taxRate) => taxRate.totalTaxRate
      );

      setTaxRateData({
        labels: taxRatesByCountry,
        datasets: [{ ...taxRateData.datasets[0], data: totalTaxRates }],
      });
    } catch (error) {
      console.error("Error fetching tax rates:", error);
    }
  };

  const fetchExpenses = async () => {
    const token = getToken();
    try {
      const response = await axios.get("https://trio-teach-backend-erp.vercel.app/api/expenses", {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Process the fetched expenses data
      const expensesByCategory = {}; // Example: Group expenses by category
      response.data.forEach((expense) => {
        const categoryName = expense.category.name; // Assuming category is populated
        if (!expensesByCategory[categoryName]) {
          expensesByCategory[categoryName] = 0;
        }
        expensesByCategory[categoryName] += expense.total;
      });

      setExpenseData({
        labels: Object.keys(expensesByCategory),
        datasets: [
          {
            ...expenseData.datasets[0],
            data: Object.values(expensesByCategory),
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching expenses:", error);
    }
  };

  // Function to fetch product data
  const fetchProductData = async () => {
    const token = getToken();
    try {
      const response = await axios.get("https://trio-teach-backend-erp.vercel.app/api/products", {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Assuming your API returns an array of products
      const productNames = response.data.map((product) => product.name);
      const productPrices = response.data.map((product) => product.price);
      setChartData({
        ...chartData,
        labels: productNames,
        datasets: [{ ...chartData.datasets[0], data: productPrices }],
      });
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchInvoices = async () => {
    const token = getToken();
    try {
      const response = await axios.get(
        "https://trio-teach-backend-erp.vercel.app/api/invoices/getall",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const invoiceData = response.data.map((invoice) => invoice.total);
      const invoiceLabels = response.data.map(
        (invoice, index) => `Invoice ${index + 1}`
      );
      setInvoiceChartData({
        labels: invoiceLabels,
        datasets: [{ ...invoiceChartData.datasets[0], data: invoiceData }],
      });
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  // Function to fetch client data

  useEffect(() => {
    fetchProductData();
    fetchInvoices();
    fetchExpenses(); // Fetch expenses
    fetchTaxRates(); // Add this line to fetch tax rates
  }, []);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Total Expenses by Category",
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Categories",
        },
      },
      y: {
        title: {
          display: true,
          text: "Total Expense",
        },
        beginAtZero: true,
      },
    },
    animations: {
      tension: {
        duration: 1000,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },
  };

  return (
    <div className="charts-container">
      {/* Chart 1: Product Prices */}
      <div className="chart-box">
        <h2 className="chart-title">Product Prices</h2>{" "}
        {/* Optional: Chart title */}
        <Bar
          className="chart"
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: { position: "top" },
              title: {
                display: true,
                text: "Product Prices",
              },
            },
          }}
        />
      </div>

      <div className="chart-box">
        <h2 className="chart-title">Invoice Totals</h2>
        <Bar
          className="chart"
          data={invoiceChartData}
          options={{
            responsive: true,
            plugins: {
              legend: { position: "top" },
              title: {
                display: true,
                text: "Invoice Totals",
              },
            },
          }}
        />
      </div>

      {/* Placeholder for Chart 3 */}
      <div className="chart-box">
        <h2 className="chart-title">Tax Rates by Country and Province</h2>
        <Bar
          className="chart"
          data={taxRateData}
          options={{
            responsive: true,
            plugins: {
              legend: { position: "top" },
              title: {
                display: true,
                text: "Total Tax Rates",
              },
            },
          }}
        />
      </div>

      {/* Placeholder for Chart 4 */}
      <div className="chart-box">
        <h2 className="chart-title">Expenses Breakdown</h2>
        <Bar
          className="chart"
          data={expenseData}
          options={chartOptions} // Use the updated options here
        />
      </div>
    </div>
  );
};

export default DashboardChart;
