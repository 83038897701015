import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import "../css/SharedLayout.css";
import "../css/ExpensePage.css"; // Adjust the path according to your file structure
import { getToken } from "../service/auth"; // Ensure this path matches your project structure
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ENDPOINT = "https://trio-teach-backend-erp.vercel.app";

function ExpensePage() {
  const [isEditMode, setIsEditMode] = useState(false); // State to determine if the form is in edit mode
  const [currentExpenseId, setCurrentExpenseId] = useState(null); // State to hold the ID of the expense being edited
  const [activeDropdown, setActiveDropdown] = useState(null); // Step 1
  const [expenses, setExpenses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [name, setName] = useState("");
  const [currency, setCurrency] = useState("EUR");
  const [total, setTotal] = useState("");
  const [description, setDescription] = useState("");

  const fetchExpenses = async () => {
    try {
      const response = await axios.get(`${ENDPOINT}/api/expenses`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setExpenses(response.data);
    } catch (error) {
      console.error("Error fetching expenses:", error);
    }
  };

  const fetchExpenseCategories = async () => {
    try {
      const response = await axios.get(`${ENDPOINT}/api/expense-categories`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching expense categories:", error);
    }
  };

  useEffect(() => {
    fetchExpenses();
    fetchExpenseCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      name,
      category: selectedCategory,
      currency,
      total,
      description,
    };

    try {
      let response;

      if (isEditMode) {
        // Update existing expense
        response = await axios.patch(
          `${ENDPOINT}/api/expenses/${currentExpenseId}`,
          payload,
          {
            headers: { Authorization: `Bearer ${getToken()}` },
          }
        );

        // Update the expenses list with the updated expense
        setExpenses(
          expenses.map((exp) =>
            exp._id === currentExpenseId ? response.data : exp
          )
        );
        toast.success("Expense updated successfully!");
      } else {
        // Create new expense
        response = await axios.post(`${ENDPOINT}/api/expenses`, payload, {
          headers: { Authorization: `Bearer ${getToken()}` },
        });

        // Add the new expense to the expenses list
        setExpenses([...expenses, response.data]);
      }
      toast.success("Expense added successfully!");

      // Reset form and exit edit mode
      resetForm();
    } catch (error) {
      console.error("Error adding/updating expense:", error);
    }
  };

  // Reset form to initial state
  const resetForm = () => {
    setName("");
    setSelectedCategory("");
    setCurrency("EUR");
    setTotal("");
    setDescription("");
    setIsEditMode(false);
    setCurrentExpenseId(null);
  };
  const toggleDropdown = (expenseId) => {
    setActiveDropdown(activeDropdown === expenseId ? null : expenseId);
  };

  const handleUpdate = (expense) => {
    // Prefill form with expense data
    setName(expense.name);
    setSelectedCategory(expense.category._id); // assuming category is an object with _id
    setCurrency(expense.currency);
    setTotal(expense.total);
    setDescription(expense.description);

    // Set state for edit mode
    setIsEditMode(true);
    setCurrentExpenseId(expense._id);

    // Close the dropdown
    setActiveDropdown(null);
  };

  // Step 3: Function to handle expense delete
  const handleDelete = async (expenseId) => {
    try {
      const response = await axios.delete(
        `${ENDPOINT}/api/expenses/${expenseId}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      // Logic to handle expense deletion (e.g., refresh the expenses list)
      console.log("Deleted expense:", response.data);
      fetchExpenses(); // Call fetchExpenses to refresh the list
      toast.success("Expense deleted successfully!");
    } catch (error) {
      console.error("Error deleting expense:", error);
    }
  };

  return (
    <div className="layout">
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="companyPage_container">
        <div className="main-content">
          <h1>Expenses Page</h1>
          <p>Manage your expenses here.</p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              required
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
            <select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              <option value="EUR">Euro</option>
              <option value="USD">Dollar</option>
            </select>
            <input
              type="number"
              placeholder="Total"
              value={total}
              onChange={(e) => setTotal(e.target.value)}
              required
            />
            <textarea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            <button type="submit">Add Expense</button>
          </form>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Category</th>
                <th>Currency</th>
                <th>Total</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {expenses.map((expense) => (
                <tr key={expense._id}>
                  <td>{expense.name}</td>
                  <td>
                    {expense.category ? expense.category.name : "No Category"}
                  </td>{" "}
                  {/* Change here */}
                  <td>{expense.currency}</td>
                  <td>{expense.total}</td>
                  <td>{expense.description}</td>
                  <td>
                    <div className="relative-container">
                      <button onClick={() => toggleDropdown(expense._id)}>
                        ...
                      </button>
                      {activeDropdown === expense._id && (
                        <div className="dropdown-content show">
                          <button onClick={() => handleUpdate(expense)}>
                            Update
                          </button>
                          <button onClick={() => handleDelete(expense._id)}>
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ExpensePage;
