import React, { useState, useEffect, useRef, createRef } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import "../css/SharedLayout.css";
import { getToken } from "../service/auth";
import "../css/PeoplePage.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_ENDPOINT = "https://trio-teach-backend-erp.vercel.app";
const api = axios.create({ baseURL: API_ENDPOINT });

api.interceptors.request.use(function (config) {
  const token = getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function PeoplePage() {
  const [people, setPeople] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    country: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    fetchPeople();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current.every((ref) => ref && !ref.contains(event.target))
      ) {
        setActiveDropdown(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchPeople();
    }, 500); // Will trigger search 500ms after the user stops typing

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]); // Effect dependencies

  const fetchPeople = async () => {
    try {
      // Updated to include search term in the request
      const response = await api.get("/people", {
        params: { search: searchTerm.trim() },
      });
      setPeople(response.data);
    } catch (error) {
      console.error("Error fetching people:", error);
    }
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const action = selectedPerson
        ? api.patch(`/people/${selectedPerson._id}`, formData)
        : api.post("/people", formData);
      await action;
      // Show success toast notification
      toast.success(selectedPerson ? "Person updated successfully!" : "Person added successfully!");

      setIsAdding(false);
      setSelectedPerson(null);
      setActiveDropdown(null);
      fetchPeople();
      setFormData({
        firstName: "",
        lastName: "",
        company: "",
        country: "",
        phone: "",
        email: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleAddClick = () => {
    setIsAdding(true);
    setSelectedPerson(null);
    setActiveDropdown(null);
    setFormData({
      firstName: "",
      lastName: "",
      company: "",
      country: "",
      phone: "",
      email: "",
    });
  };

  const handleEditClick = (person) => {
    setIsAdding(false);
    setSelectedPerson(person);
    toast.success(selectedPerson ? "Person updated successfully!" : "Person added successfully!");
    setFormData({
      firstName: person.firstName,
      lastName: person.lastName,
      company: person.company,
      country: person.country,
      phone: person.phone,
      email: person.email,
    });
    setActiveDropdown(null);
  };

  const handleDeleteClick = async (id) => {
    try {
      await api.delete(`/people/${id}`);
      fetchPeople();
      toast.success("Person deleted successfully!");
    } catch (error) {
      console.error("Error deleting person:", error);
    }
    setActiveDropdown(null);
  };

  const handleCancelClick = () => {
    setIsAdding(false);
    setSelectedPerson(null);
    setActiveDropdown(null);
  };

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    if (e.key === "Enter") {
      // Checks if the Enter key was pressed
      e.preventDefault(); // Prevents the default form submission
      fetchPeople();
    }
  };

  return (
    <div className="layout">
      <Sidebar />
      {/* Company */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="companyPage_container">
        <div className="main-content">
          <div className="main-header">
            <h1>People List</h1>
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleSearchSubmit} // Listening for key down event
              />

              <button className="refresh-button">Refresh</button>
              <button className="add-button" onClick={handleAddClick}>
                Add New Person
              </button>
            </div>
          </div>

          <table className="people-table">
            <thead className="tes12">
              <tr>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Company</th>
                <th>Country</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {people.map((person, index) => (
                <tr key={person._id}>
                  <td>{person.firstName}</td>
                  <td>{person.lastName}</td>
                  <td>{person.company}</td>
                  <td>{person.country}</td>
                  <td>{person.phone}</td>
                  <td>{person.email}</td>
                  <td>
                    <div
                      className="action-buttons"
                      ref={(el) => (dropdownRef.current[index] = el)}
                    >
                      <div className="relative-container">
                        <button
                          className="action-button"
                          onClick={() => toggleDropdown(index)}
                        >
                          ...
                        </button>
                        {activeDropdown === index && (
                          <div className="dropdown-content show">
                            <button onClick={() => handleEditClick(person)}>
                              Edit
                            </button>
                            <button
                              onClick={() => handleDeleteClick(person._id)}
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {(isAdding || selectedPerson) && (
            <div className="person-form">
              <form onSubmit={handleSubmit}>
                <label>
                  First Name:{" "}
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleFormChange}
                  />
                </label>
                <label>
                  Last Name:{" "}
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleFormChange}
                  />
                </label>
                <label>
                  Company:{" "}
                  <input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleFormChange}
                  />
                </label>
                <label>
                  Country:{" "}
                  <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleFormChange}
                  />
                </label>
                <label>
                  Phone:{" "}
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleFormChange}
                  />
                </label>
                <label>
                  Email:{" "}
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleFormChange}
                  />
                </label>
                <button type="submit">Submit</button>
                <button type="button" onClick={handleCancelClick}>
                  Cancel
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PeoplePage;
