import React, { useState, useEffect, useRef, createRef } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import "../css/CompanyPage.css";
import { getToken } from "../service/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_ENDPOINT = "https://trio-teach-backend-erp.vercel.app/companies";

const api = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

function CompanyPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [companies, setCompanies] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef([]);

  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    country: "",
    phone: "",
    email: "",
    website: "",
  });

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    fetchCompanies();
  }, [searchTerm]); // Optionally, you can remove searchTerm from here and trigger fetchCompanies explicitly on search

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current.every((ref) => ref && !ref.contains(event.target))
      ) {
        setActiveDropdown(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchCompanies();
    }, 500); // Delay in ms

    return () => clearTimeout(handler);
  }, [searchTerm]);

  const fetchCompanies = async () => {
    try {
      const response = await api.get("/", {
        params: { search: searchTerm.trim() },
      });
      setCompanies(response.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Check if the pressed key is 'Enter'
      e.preventDefault(); // Prevent the default action
      fetchCompanies(); // Fetch companies based on the current searchTerm
    }
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const action = selectedCompany
        ? api.patch(`/${selectedCompany._id}`, formData)
        : api.post("/", formData);
      await action;
      setIsEditing(false);
      setSelectedCompany(null);
      setActiveDropdown(null);
      fetchCompanies();
      setFormData({
        name: "",
        contact: "",
        country: "",
        phone: "",
        email: "",
        website: "",
      });
      toast.success("Company updated successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleAddClick = () => {
    setIsEditing(true);
    setSelectedCompany(null);
    setActiveDropdown(null);

    setFormData({
      name: "",
      contact: "",
      country: "",
      phone: "",
      email: "",
      website: "",
    });
    toast.success("Company added successfully!");
  };

  const handleEditClick = (company) => {
    setIsEditing(true);
    setSelectedCompany(company);
    setFormData(company);
    setActiveDropdown(null);
  };

  const handleDeleteClick = async (id) => {
    try {
      await api.delete(`/${id}`);
      fetchCompanies();
      toast.success("Company deleted successfully!");
    } catch (error) {
      console.error("Error deleting company:", error);
    }
    setActiveDropdown(null);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setSelectedCompany(null);
    setActiveDropdown(null);
  };

  const toggleDropdown = (index) => {
    // This function should toggle the 'active' class on the dropdown-content div
    // You need to adjust your current code to handle this logic
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <div className="layout">
      <Sidebar />
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className="companyPage_container">
        <div>
          <div className="main-content">
            <div className="main-header">
              <h1>Company Management</h1>
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyPress={handleKeyPress} // Listen for key press events
                />

                <button className="add-button" onClick={handleAddClick}>
                  Add New Company
                </button>
              </div>
            </div>

            <table className="company-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Country</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Website</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {companies.map((company, index) => (
                  <tr key={company._id}>
                    <td>{company.name}</td>
                    <td>{company.contact}</td>
                    <td>{company.country}</td>
                    <td>{company.phone}</td>
                    <td>{company.email}</td>
                    <td>{company.website}</td>
                    <td>
                      <div
                        className="action-buttons"
                        ref={(el) => (dropdownRef.current[index] = el)}
                      >
                        <div className="relative-container">
                          <button
                            className="action-button"
                            onClick={() => toggleDropdown(index)}
                          >
                            ...
                          </button>
                          {activeDropdown === index && (
                            <div className="dropdown-content show">
                              <button onClick={() => handleEditClick(company)}>
                                Edit
                              </button>
                              <button
                                onClick={() => handleDeleteClick(company._id)}
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {(isEditing || selectedCompany) && (
              <div className="company-form">
                <form onSubmit={handleSubmit}>
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleFormChange}
                  />
                  <label>Contact:</label>
                  <input
                    type="text"
                    name="contact"
                    value={formData.contact}
                    onChange={handleFormChange}
                  />
                  <label>Country:</label>
                  <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleFormChange}
                  />
                  <label>Phone:</label>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleFormChange}
                  />
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleFormChange}
                  />
                  <label>Website:</label>
                  <input
                    type="text"
                    name="website"
                    value={formData.website}
                    onChange={handleFormChange}
                  />
                  <div className="form-actions">
                    <button type="submit" className="submit-button">
                      Save Company
                    </button>
                    <button
                      type="button"
                      onClick={handleCancelClick}
                      className="cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyPage;
