import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import { getToken } from "../service/auth";
import "../css/SharedLayout.css";
import "../css/InvoiceTable.css";
import axios from "axios";
import generateInvoicePDF from "./PDFService"; // Adjust the path as necessary
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function InvoicePage() {
  const [clients, setClients] = useState([]);
  const [businessInfo, setBusinessInfo] = useState({});
  const [productCategories, setProductCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [taxRates, setTaxRates] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [newInvoice, setNewInvoice] = useState({
    clientId: "",
    productCategoryId: "",
    productId: "",
    taxRateId: "",
    price: 0,
    quantity: 0,
    status: "",
    currency: "",
    date: "",
    expireDate: "",
    note: "",
    description: "",
    subtotal: 0,
    total: 0,
  });

  // Fetch all necessary data on component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch clients
        const clientsRes = await fetch("https://trio-teach-backend-erp.vercel.app/clients", {
          headers: { Authorization: `Bearer ${getToken()}` },
        });
        const clientsData = await clientsRes.json();

        // Fetch product categories
        const productCategoriesRes = await fetch(
          "https://trio-teach-backend-erp.vercel.app/api/product-categories",
          {
            headers: { Authorization: `Bearer ${getToken()}` },
          }
        );
        const productCategoriesData = await productCategoriesRes.json();

        // Fetch products
        const productsRes = await fetch("https://trio-teach-backend-erp.vercel.app/api/products", {
          headers: { Authorization: `Bearer ${getToken()}` },
        });
        const productsData = await productsRes.json();

        // Fetch tax rates
        const taxRatesRes = await fetch("https://trio-teach-backend-erp.vercel.app/api/tax-rates", {
          headers: { Authorization: `Bearer ${getToken()}` },
        });
        const taxRatesData = await taxRatesRes.json();

        // Fetch invoices
        const invoicesRes = await fetch(
          "https://trio-teach-backend-erp.vercel.app/api/invoices/getall",
          {
            headers: { Authorization: `Bearer ${getToken()}` },
          }
        );
        const invoicesData = await invoicesRes.json();
        setInvoices(
          invoicesData.map((inv) => ({ ...inv, entries: inv.entries || [] }))
        ); // Ensure entries is always an array

        setClients(clientsData);
        setProductCategories(productCategoriesData);
        setProducts(productsData);
        setTaxRates(taxRatesData);
        setInvoices(invoicesData);
      } catch (err) {
        console.error("Failed to fetch data:", err);
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Now calculateTotals is called with the current newInvoice state
    const { subtotal, total, taxAmount } = calculateTotals(newInvoice);
    setNewInvoice((prev) => ({
      ...prev,
      subtotal,
      total,
      taxAmount,
    }));
  }, [newInvoice.price, newInvoice.quantity, newInvoice.taxRateId, taxRates]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`${name}: ${value}`);
    setNewInvoice((prevState) => {
      // Start with the current state
      let updatedInvoice = { ...prevState, [name]: value };

      // If the product ID changed, update the price field as well
      if (name === "productId") {
        // Find the selected product based on its ID
        const selectedProduct = products.find(
          (product) => product._id === value
        );

        // If a product is found, update the price in the state
        if (selectedProduct) {
          updatedInvoice = { ...updatedInvoice, price: selectedProduct.price };
        }
      }

      // If one of the critical values changes, recalculate totals immediately
      if (["price", "quantity", "taxRateId"].includes(name)) {
        return { ...updatedInvoice, ...calculateTotals(updatedInvoice) };
      }

      return updatedInvoice;
    });
  };

  const renderOptions = (items) => {
    return items.map((item) => (
      <option key={item._id} value={item._id}>
        {item.name || item.categoryName || item.country || "Unknown"}
      </option>
    ));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(""); // Clear previous error message
    setSuccessMessage(""); // Clear previous success message

    console.log("clientId:", newInvoice.clientId);
    console.log("productCategoryId:", newInvoice.productCategoryId);
    console.log("productId:", newInvoice.productId);
    console.log("taxRateId:", newInvoice.taxRateId);
    console.log("price:", newInvoice.price);
    console.log("quantity:", newInvoice.quantity);
    console.log("status:", newInvoice.status);
    console.log("currency:", newInvoice.currency);
    console.log("date:", newInvoice.date);
    console.log("expireDate:", newInvoice.expireDate);
    console.log("note:", newInvoice.note);
    console.log("description:", newInvoice.description);

    try {
      const response = await fetch("https://trio-teach-backend-erp.vercel.app/api/invoices/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(newInvoice),
      });

      if (!response.ok) {
        throw new Error("Failed to save the invoice");
      }

      // Assume response contains the newly added invoice
      const newInvoiceData = await response.json();
      setInvoices((prevInvoices) => [...prevInvoices, newInvoiceData]);
      setSuccessMessage("Invoice saved successfully!");
      toast.success("Invoice saved successfully!");

      // Reset form
      setNewInvoice({
        clientId: "",
        productCategoryId: "",
        productId: "",
        taxRateId: "",
        price: "",
        quantity: "",
        status: "",
        currency: "",
        date: "",
        expireDate: "",
        note: "",
        description: "",
        invoiceNumber: "", // Add invoiceNumber field
      });
    } catch (err) {
      setError("Could not save the invoice. Please try again later.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotals = (currentInvoice) => {
    if (!currentInvoice || !taxRates) {
      return { subtotal: 0, total: 0, taxAmount: 0 };
    }

    // Assuming you find the correct tax rate like before
    const taxRateObj = taxRates.find(
      (rate) => rate._id === currentInvoice.taxRateId
    );
    const taxPercentage = taxRateObj ? taxRateObj.totalTaxRate : 0;
    const subtotal = currentInvoice.price * currentInvoice.quantity;
    const taxAmount = (subtotal * taxPercentage) / 100;
    const total = subtotal + taxAmount;

    return { subtotal, total, taxAmount }; // Always return an object
  };

  const downloadInvoicePDF = (invoice) => {
    // You might need to adjust the invoice object structure based on your state
    generateInvoicePDF(invoice, businessInfo);
    toast.success("Invoice PDF downloaded successfully!");
  };

  const fetchBusinessInfo = async () => {
    setLoading(true); // Use existing loading state to indicate loading
    try {
      const token = getToken(); // Assuming getToken() retrieves the current user's token
      const response = await axios.get("https://trio-teach-backend-erp.vercel.app/api/business", {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data && response.data.length > 0) {
        // Assuming the response contains an array of businesses and we're interested in the first one
        setBusinessInfo(response.data[0]);
      } else {
        console.error("Business information not found");
      }
    } catch (error) {
      console.error("Failed to fetch business information:", error);
      setError("Failed to fetch business information. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBusinessInfo();
    // You can also include other data fetching calls here or in separate useEffect hooks
  }, []);
  // Example of setting the taxAmount when creating/updating the invoice

  return (
    <div className="invoice-page-layout">
      <Sidebar />
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="companyPage_container">
        <div className="main-content">
          <h1 className="page-title">Invoice Page</h1>
          <p className="page-description">Manage your invoices here.</p>

          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="error-message">{error}</p>
          ) : (
            <>
              <form className="invoice-form" onSubmit={handleSubmit}>
                <div className="form-row">
                  <select
                    className="form-input"
                    name="clientId"
                    value={newInvoice.clientId}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Client</option>
                    {renderOptions(clients)}
                  </select>
                  <select
                    className="form-input"
                    name="productCategoryId"
                    value={newInvoice.productCategoryId}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Product Category</option>
                    {renderOptions(productCategories)}
                  </select>
                  <select
                    className="form-input"
                    name="productId"
                    value={newInvoice.productId}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Product</option>
                    {renderOptions(products)}
                  </select>
                </div>
                <div className="form-row">
                  <input
                    className="form-input"
                    type="number"
                    name="price"
                    value={newInvoice.price}
                    onChange={handleInputChange}
                    placeholder="Price"
                    required
                  />
                  <input
                    className="form-input"
                    type="number"
                    name="quantity"
                    value={newInvoice.quantity}
                    onChange={handleInputChange}
                    placeholder="Quantity"
                    required
                  />
                  <input
                    className="form-input"
                    type="number"
                    name="subtotal"
                    value={newInvoice.subtotal}
                    onChange={handleInputChange}
                    placeholder="Subtotal"
                    required
                  />
                </div>
                <div className="form-row">
                  <select
                    className="form-input"
                    name="taxRateId"
                    value={newInvoice.taxRateId}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Tax Rate</option>
                    {taxRates.map((taxRate) => (
                      <option key={taxRate._id} value={taxRate._id}>
                        {taxRate.country} - {taxRate.province} -{" "}
                        {taxRate.totalTaxRate}%
                      </option>
                    ))}
                  </select>
                  <input
                    className="form-input"
                    type="number"
                    name="total"
                    value={newInvoice.total}
                    onChange={handleInputChange}
                    placeholder="Total after tax"
                    required
                  />
                  <input
                    className="form-input"
                    type="text"
                    name="invoiceNumber"
                    value={newInvoice.invoiceNumber}
                    onChange={handleInputChange}
                    placeholder="Invoice Number"
                    required
                  />
                </div>
                <div className="form-row">
                  <select
                    className="form-input"
                    name="status"
                    value={newInvoice.status}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="Paid">Paid</option>
                    <option value="Unpaid">Unpaid</option>
                  </select>
                  <select
                    className="form-input"
                    name="currency"
                    value={newInvoice.currency}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Currency</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                  </select>
                  <input
                    className="form-input"
                    type="date"
                    name="date"
                    value={newInvoice.date}
                    onChange={handleInputChange}
                    placeholder="Date"
                    required
                  />
                  <input
                    className="form-input"
                    type="date"
                    name="expireDate"
                    value={newInvoice.expireDate}
                    onChange={handleInputChange}
                    placeholder="Expire Date"
                    required
                  />
                </div>
                <div className="form-row">
                  <textarea
                    className="form-input"
                    name="note"
                    value={newInvoice.note}
                    onChange={handleInputChange}
                    placeholder="Note"
                  />
                  <textarea
                    className="form-input"
                    name="description"
                    value={newInvoice.description}
                    onChange={handleInputChange}
                    placeholder="Description"
                  />
                  <button className="form-button" type="submit">
                    Save Invoice
                  </button>
                </div>
              </form>
              <div className="invoice-table-wrapper">
                <h2>Existing Invoices</h2>
                <table className="table-invoices">
                  <thead>
                    <tr>
                      <th>Client</th>
                      <th>Product Name</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Status</th>
                      {/* <th>Currency</th> */}
                      <th>Date</th>
                      <th>Invoice Number</th>
                      <th>PDF</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices.map((invoice) => (
                      <tr key={invoice._id}>
                        <td>{invoice.client?.name || "Unknown Client"}</td>
                        <td>
                          {invoice.product
                            ? invoice.product.name
                            : "Unknown Product"}
                        </td>
                        <td>{invoice.price}$</td>
                        <td>{invoice.quantity}</td>
                        <td>{invoice.total}</td>
                        <td>{invoice.status}</td>
                        <td>{invoice.date}</td>
                        <td>{invoice.invoiceNumber}</td>
                        <td>
                          <button
                            className="download-pdf-button"
                            onClick={() => downloadInvoicePDF(invoice)}
                          >
                            Download PDF
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default InvoicePage;
